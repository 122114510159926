let wasm;

function debugString(val) {
    // primitive types
    const type = typeof val;
    if (type == 'number' || type == 'boolean' || val == null) {
        return  `${val}`;
    }
    if (type == 'string') {
        return `"${val}"`;
    }
    if (type == 'symbol') {
        const description = val.description;
        if (description == null) {
            return 'Symbol';
        } else {
            return `Symbol(${description})`;
        }
    }
    if (type == 'function') {
        const name = val.name;
        if (typeof name == 'string' && name.length > 0) {
            return `Function(${name})`;
        } else {
            return 'Function';
        }
    }
    // objects
    if (Array.isArray(val)) {
        const length = val.length;
        let debug = '[';
        if (length > 0) {
            debug += debugString(val[0]);
        }
        for(let i = 1; i < length; i++) {
            debug += ', ' + debugString(val[i]);
        }
        debug += ']';
        return debug;
    }
    // Test for built-in
    const builtInMatches = /\[object ([^\]]+)\]/.exec(toString.call(val));
    let className;
    if (builtInMatches && builtInMatches.length > 1) {
        className = builtInMatches[1];
    } else {
        // Failed to match the standard '[object ClassName]'
        return toString.call(val);
    }
    if (className == 'Object') {
        // we're a user defined class or Object
        // JSON.stringify avoids problems with cycles, and is generally much
        // easier than looping through ownProperties of `val`.
        try {
            return 'Object(' + JSON.stringify(val) + ')';
        } catch (_) {
            return 'Object';
        }
    }
    // errors
    if (val instanceof Error) {
        return `${val.name}: ${val.message}\n${val.stack}`;
    }
    // TODO we could test for more things here, like `Set`s and `Map`s.
    return className;
}

let WASM_VECTOR_LEN = 0;

let cachedUint8ArrayMemory0 = null;

function getUint8ArrayMemory0() {
    if (cachedUint8ArrayMemory0 === null || cachedUint8ArrayMemory0.byteLength === 0) {
        cachedUint8ArrayMemory0 = new Uint8Array(wasm.memory.buffer);
    }
    return cachedUint8ArrayMemory0;
}

const cachedTextEncoder = (typeof TextEncoder !== 'undefined' ? new TextEncoder('utf-8') : { encode: () => { throw Error('TextEncoder not available') } } );

const encodeString = (typeof cachedTextEncoder.encodeInto === 'function'
    ? function (arg, view) {
    return cachedTextEncoder.encodeInto(arg, view);
}
    : function (arg, view) {
    const buf = cachedTextEncoder.encode(arg);
    view.set(buf);
    return {
        read: arg.length,
        written: buf.length
    };
});

function passStringToWasm0(arg, malloc, realloc) {

    if (realloc === undefined) {
        const buf = cachedTextEncoder.encode(arg);
        const ptr = malloc(buf.length, 1) >>> 0;
        getUint8ArrayMemory0().subarray(ptr, ptr + buf.length).set(buf);
        WASM_VECTOR_LEN = buf.length;
        return ptr;
    }

    let len = arg.length;
    let ptr = malloc(len, 1) >>> 0;

    const mem = getUint8ArrayMemory0();

    let offset = 0;

    for (; offset < len; offset++) {
        const code = arg.charCodeAt(offset);
        if (code > 0x7F) break;
        mem[ptr + offset] = code;
    }

    if (offset !== len) {
        if (offset !== 0) {
            arg = arg.slice(offset);
        }
        ptr = realloc(ptr, len, len = offset + arg.length * 3, 1) >>> 0;
        const view = getUint8ArrayMemory0().subarray(ptr + offset, ptr + len);
        const ret = encodeString(arg, view);

        offset += ret.written;
        ptr = realloc(ptr, len, offset, 1) >>> 0;
    }

    WASM_VECTOR_LEN = offset;
    return ptr;
}

let cachedDataViewMemory0 = null;

function getDataViewMemory0() {
    if (cachedDataViewMemory0 === null || cachedDataViewMemory0.buffer.detached === true || (cachedDataViewMemory0.buffer.detached === undefined && cachedDataViewMemory0.buffer !== wasm.memory.buffer)) {
        cachedDataViewMemory0 = new DataView(wasm.memory.buffer);
    }
    return cachedDataViewMemory0;
}

const cachedTextDecoder = (typeof TextDecoder !== 'undefined' ? new TextDecoder('utf-8', { ignoreBOM: true, fatal: true }) : { decode: () => { throw Error('TextDecoder not available') } } );

if (typeof TextDecoder !== 'undefined') { cachedTextDecoder.decode(); };

function getStringFromWasm0(ptr, len) {
    ptr = ptr >>> 0;
    return cachedTextDecoder.decode(getUint8ArrayMemory0().subarray(ptr, ptr + len));
}
/**
 * @param {number} fy1
 * @param {number} fy2
 * @returns {any}
 */
export function compute_growth_rate(fy1, fy2) {
    const ret = wasm.compute_growth_rate(fy1, fy2);
    return ret;
}

/**
 * @param {number} beta
 * @param {number} rfr
 * @param {number} risk_premium
 * @returns {any}
 */
export function compute_cost_of_equity(beta, rfr, risk_premium) {
    const ret = wasm.compute_cost_of_equity(beta, rfr, risk_premium);
    return ret;
}

/**
 * @param {number} fy0
 * @param {number} fy1
 * @param {number} fy2
 * @param {number} months_to_fye
 * @param {number} growth_rate
 * @returns {any}
 */
export function compute_earnings_forecast(fy0, fy1, fy2, months_to_fye, growth_rate) {
    const ret = wasm.compute_earnings_forecast(fy0, fy1, fy2, months_to_fye, growth_rate);
    return ret;
}

/**
 * Compute the intrinsic value and additional metrics.
 * @param {number} fe0
 * @param {number} fe1
 * @param {number} fe2
 * @param {number} cost_of_equity
 * @param {number} shares
 * @param {number} debt
 * @param {number} cash
 * @param {number} plowback_rate
 * @param {number} eps_growth
 * @param {number} stock_price
 * @param {number} growth_rate
 * @param {number} forecast_period
 * @param {number} steady_roi
 * @returns {any}
 */
export function compute_intrinsic_value(fe0, fe1, fe2, cost_of_equity, shares, debt, cash, plowback_rate, eps_growth, stock_price, growth_rate, forecast_period, steady_roi) {
    const ret = wasm.compute_intrinsic_value(fe0, fe1, fe2, cost_of_equity, shares, debt, cash, plowback_rate, eps_growth, stock_price, growth_rate, forecast_period, steady_roi);
    return ret;
}

const CostOfEquityResultFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_costofequityresult_free(ptr >>> 0, 1));

export class CostOfEquityResult {

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        CostOfEquityResultFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_costofequityresult_free(ptr, 0);
    }
    /**
     * @returns {number}
     */
    get adjusted_beta() {
        const ret = wasm.__wbg_get_costofequityresult_adjusted_beta(this.__wbg_ptr);
        return ret;
    }
    /**
     * @param {number} arg0
     */
    set adjusted_beta(arg0) {
        wasm.__wbg_set_costofequityresult_adjusted_beta(this.__wbg_ptr, arg0);
    }
    /**
     * @returns {number}
     */
    get cost_of_equity() {
        const ret = wasm.__wbg_get_costofequityresult_cost_of_equity(this.__wbg_ptr);
        return ret;
    }
    /**
     * @param {number} arg0
     */
    set cost_of_equity(arg0) {
        wasm.__wbg_set_costofequityresult_cost_of_equity(this.__wbg_ptr, arg0);
    }
}

const EarningsForecastResultFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_earningsforecastresult_free(ptr >>> 0, 1));

export class EarningsForecastResult {

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EarningsForecastResultFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_earningsforecastresult_free(ptr, 0);
    }
    /**
     * @returns {number}
     */
    get fe0() {
        const ret = wasm.__wbg_get_costofequityresult_adjusted_beta(this.__wbg_ptr);
        return ret;
    }
    /**
     * @param {number} arg0
     */
    set fe0(arg0) {
        wasm.__wbg_set_costofequityresult_adjusted_beta(this.__wbg_ptr, arg0);
    }
    /**
     * @returns {number}
     */
    get fe1() {
        const ret = wasm.__wbg_get_costofequityresult_cost_of_equity(this.__wbg_ptr);
        return ret;
    }
    /**
     * @param {number} arg0
     */
    set fe1(arg0) {
        wasm.__wbg_set_costofequityresult_cost_of_equity(this.__wbg_ptr, arg0);
    }
    /**
     * @returns {number}
     */
    get fe2() {
        const ret = wasm.__wbg_get_earningsforecastresult_fe2(this.__wbg_ptr);
        return ret;
    }
    /**
     * @param {number} arg0
     */
    set fe2(arg0) {
        wasm.__wbg_set_earningsforecastresult_fe2(this.__wbg_ptr, arg0);
    }
}

const ValuationResultFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_valuationresult_free(ptr >>> 0, 1));

export class ValuationResult {

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        ValuationResultFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_valuationresult_free(ptr, 0);
    }
    /**
     * @returns {number}
     */
    get cost_of_equity_implied() {
        const ret = wasm.__wbg_get_costofequityresult_adjusted_beta(this.__wbg_ptr);
        return ret;
    }
    /**
     * @param {number} arg0
     */
    set cost_of_equity_implied(arg0) {
        wasm.__wbg_set_costofequityresult_adjusted_beta(this.__wbg_ptr, arg0);
    }
    /**
     * @returns {number}
     */
    get intrinsic_value() {
        const ret = wasm.__wbg_get_costofequityresult_cost_of_equity(this.__wbg_ptr);
        return ret;
    }
    /**
     * @param {number} arg0
     */
    set intrinsic_value(arg0) {
        wasm.__wbg_set_costofequityresult_cost_of_equity(this.__wbg_ptr, arg0);
    }
    /**
     * @returns {number}
     */
    get pv_ratio() {
        const ret = wasm.__wbg_get_earningsforecastresult_fe2(this.__wbg_ptr);
        return ret;
    }
    /**
     * @param {number} arg0
     */
    set pv_ratio(arg0) {
        wasm.__wbg_set_earningsforecastresult_fe2(this.__wbg_ptr, arg0);
    }
    /**
     * @returns {number}
     */
    get assets_in_place() {
        const ret = wasm.__wbg_get_valuationresult_assets_in_place(this.__wbg_ptr);
        return ret;
    }
    /**
     * @param {number} arg0
     */
    set assets_in_place(arg0) {
        wasm.__wbg_set_valuationresult_assets_in_place(this.__wbg_ptr, arg0);
    }
    /**
     * @returns {number}
     */
    get pvgo() {
        const ret = wasm.__wbg_get_valuationresult_pvgo(this.__wbg_ptr);
        return ret;
    }
    /**
     * @param {number} arg0
     */
    set pvgo(arg0) {
        wasm.__wbg_set_valuationresult_pvgo(this.__wbg_ptr, arg0);
    }
    /**
     * @returns {number}
     */
    get value_of_equity() {
        const ret = wasm.__wbg_get_valuationresult_value_of_equity(this.__wbg_ptr);
        return ret;
    }
    /**
     * @param {number} arg0
     */
    set value_of_equity(arg0) {
        wasm.__wbg_set_valuationresult_value_of_equity(this.__wbg_ptr, arg0);
    }
    /**
     * @returns {number}
     */
    get firm_value() {
        const ret = wasm.__wbg_get_valuationresult_firm_value(this.__wbg_ptr);
        return ret;
    }
    /**
     * @param {number} arg0
     */
    set firm_value(arg0) {
        wasm.__wbg_set_valuationresult_firm_value(this.__wbg_ptr, arg0);
    }
    /**
     * @returns {number}
     */
    get enterprise_value() {
        const ret = wasm.__wbg_get_valuationresult_enterprise_value(this.__wbg_ptr);
        return ret;
    }
    /**
     * @param {number} arg0
     */
    set enterprise_value(arg0) {
        wasm.__wbg_set_valuationresult_enterprise_value(this.__wbg_ptr, arg0);
    }
}

async function __wbg_load(module, imports) {
    if (typeof Response === 'function' && module instanceof Response) {
        if (typeof WebAssembly.instantiateStreaming === 'function') {
            try {
                return await WebAssembly.instantiateStreaming(module, imports);

            } catch (e) {
                if (module.headers.get('Content-Type') != 'application/wasm') {
                    console.warn("`WebAssembly.instantiateStreaming` failed because your server does not serve Wasm with `application/wasm` MIME type. Falling back to `WebAssembly.instantiate` which is slower. Original error:\n", e);

                } else {
                    throw e;
                }
            }
        }

        const bytes = await module.arrayBuffer();
        return await WebAssembly.instantiate(bytes, imports);

    } else {
        const instance = await WebAssembly.instantiate(module, imports);

        if (instance instanceof WebAssembly.Instance) {
            return { instance, module };

        } else {
            return instance;
        }
    }
}

function __wbg_get_imports() {
    const imports = {};
    imports.wbg = {};
    imports.wbg.__wbg_new_405e22f390576ce2 = function() {
        const ret = new Object();
        return ret;
    };
    imports.wbg.__wbg_set_3f1d0b984ed272ed = function(arg0, arg1, arg2) {
        arg0[arg1] = arg2;
    };
    imports.wbg.__wbindgen_debug_string = function(arg0, arg1) {
        const ret = debugString(arg1);
        const ptr1 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len1 = WASM_VECTOR_LEN;
        getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
        getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
    };
    imports.wbg.__wbindgen_init_externref_table = function() {
        const table = wasm.__wbindgen_export_2;
        const offset = table.grow(4);
        table.set(0, undefined);
        table.set(offset + 0, undefined);
        table.set(offset + 1, null);
        table.set(offset + 2, true);
        table.set(offset + 3, false);
        ;
    };
    imports.wbg.__wbindgen_number_new = function(arg0) {
        const ret = arg0;
        return ret;
    };
    imports.wbg.__wbindgen_string_new = function(arg0, arg1) {
        const ret = getStringFromWasm0(arg0, arg1);
        return ret;
    };
    imports.wbg.__wbindgen_throw = function(arg0, arg1) {
        throw new Error(getStringFromWasm0(arg0, arg1));
    };

    return imports;
}

function __wbg_init_memory(imports, memory) {

}

function __wbg_finalize_init(instance, module) {
    wasm = instance.exports;
    __wbg_init.__wbindgen_wasm_module = module;
    cachedDataViewMemory0 = null;
    cachedUint8ArrayMemory0 = null;


    wasm.__wbindgen_start();
    return wasm;
}

function initSync(module) {
    if (wasm !== undefined) return wasm;


    if (typeof module !== 'undefined') {
        if (Object.getPrototypeOf(module) === Object.prototype) {
            ({module} = module)
        } else {
            console.warn('using deprecated parameters for `initSync()`; pass a single object instead')
        }
    }

    const imports = __wbg_get_imports();

    __wbg_init_memory(imports);

    if (!(module instanceof WebAssembly.Module)) {
        module = new WebAssembly.Module(module);
    }

    const instance = new WebAssembly.Instance(module, imports);

    return __wbg_finalize_init(instance, module);
}

async function __wbg_init(module_or_path) {
    if (wasm !== undefined) return wasm;


    if (typeof module_or_path !== 'undefined') {
        if (Object.getPrototypeOf(module_or_path) === Object.prototype) {
            ({module_or_path} = module_or_path)
        } else {
            console.warn('using deprecated parameters for the initialization function; pass a single object instead')
        }
    }

    if (typeof module_or_path === 'undefined') {
        module_or_path = new URL('rust_wasm_bg.wasm', import.meta.url);
    }
    const imports = __wbg_get_imports();

    if (typeof module_or_path === 'string' || (typeof Request === 'function' && module_or_path instanceof Request) || (typeof URL === 'function' && module_or_path instanceof URL)) {
        module_or_path = fetch(module_or_path);
    }

    __wbg_init_memory(imports);

    const { instance, module } = await __wbg_load(await module_or_path, imports);

    return __wbg_finalize_init(instance, module);
}

export { initSync };
export default __wbg_init;
