import React, { useState } from 'react';
import '../styles/Table.css';
import { compute_growth_rate, compute_cost_of_equity, compute_earnings_forecast, compute_intrinsic_value } from '../wasm/rust_wasm';
// import TickerInput from './TickerInput.js';
import axios from 'axios';

const Table = ({ setError }) => {
    // UI State
    const [showInstructions, setShowInstructions] = useState(false);
    const [getting_values, setGettingValues] = useState(false);
    const [status, setStatus] = useState("");
    
    // Current Company
    const [ticker, setBaseTicker] = useState("");
    const [ticker_input, setTicker] = useState("");

    // Company Info
    const [corp_name, setCorpName] = useState("-");
    const [corp_industry, setCorpIndustry] = useState("-");
    // eslint-disable-next-line
    const [corp_website, setCorpWebsite] = useState("");
    // eslint-disable-next-line
    const [corp_sector, setCorpSector] = useState("-");

    // Model Tuning Params (Params Independent from Current Stock)
    const [forecast_period, setTransYears] = useState(15);
    const [risk_premium_perc, setRiskPremium] = useState(5.00);
    const [eps_growth_perc, setEpsGrowth] = useState(5.00);
    const [risk_free_rate_perc, setRiskFreeRate] = useState("");

    // Values that can be edited
    const [fy1, setFY1] = useState("");
    const [fy2, setFY2] = useState("");
    const [plowback_rate, setPlowbackRate] = useState("");
    const [growth_rate_input, setGrowthRate] = useState("");
    const [roi_perc, setROI] = useState("");

    // Main Table Values
    const [fy0, setFy0] = useState("");
    const [monthsToFYE, setMonthsToFYE] = useState("");
    const [fe0, setFe0] = useState("");
    const [fe1, setFe1] = useState("");
    const [fe2, setFe2] = useState("");
    const [book_value, setBookValue] = useState("");
    const [stock_price, setStockPrice] = useState("");
    const [shares, setShares] = useState("");
    const [debt, setDebt] = useState("");
    const [cash, setCash] = useState("");
    const [beta, setBeta] = useState("");
    const [adjusted_beta, setAdjustedBeta] = useState("");
    const [cost_of_equity, setCostOfEquity] = useState("");
    const [implied_cost_of_equity, setImpliedCostOfEquity] = useState("");
    const [intrinsic_value_of_equity_per_share_dfc, setIntrinsicValue] = useState("");
    const [profit_volume_ratio, setProfitVolumeRatio] = useState("");
    const [assets_in_place_value, setAssetsInPlace] = useState("");
    const [pvgo, setPVGO] = useState("");
    const [value_of_equity, setValueOfEquity] = useState("");
    const [total_firm_value, setFirmValue] = useState("");
    const [total_enterprise_value, setEnterpriseValue] = useState("");

    // Units
    const [shares_unit, setSharesUnit] = useState("");
    const [debt_unit, setDebtUnit] = useState("");
    const [cash_unit, setCashUnit] = useState("");
    const [value_of_equity_unit, setValueOfEquityUnit] = useState("");
    const [firm_value_unit, setFirmValueUnit] = useState("");
    const [enterprise_value_unit, setEnterpriseValueUnit] = useState("");

    // Originally Retrieved Values (For 'Restore')
    const [alt_fy1, setAltFy1] = useState("");
    const [alt_fy2, setAltFy2] = useState("");
    const [alt_plowback_rate, setAltPlowbackRate] = useState("");
    const [alt_beta, setAltBeta] = useState("");
    const [alt_rfr, setAltRFR] = useState("");
    const [orig_roi, setOrigROI] = useState("");

    // Reset all values that are dependent on current stock
    const resetValues = () => {
        // Model Params
        setRiskPremium(5.00);
        setEpsGrowth(5.00);
        setTransYears(15);

        // Reset Ticker & Company Info
        setBaseTicker("");
        setCorpName("");
        setCorpWebsite("");
        setCorpIndustry("-");
        setCorpSector("");
        
        // Reset Current Info & Computed Values
        setFy0("");
        setFY1("");
        setFY2("");
        setStockPrice("");
        setBeta("");
        setMonthsToFYE("");
        setBookValue("");
        setRiskFreeRate("");
        setShares("");
        setDebt("");
        setCash("");
        setSharesUnit("");
        setDebtUnit("");
        setCashUnit("");
        setAdjustedBeta("");
        setGrowthRate("");
        setCostOfEquity("");
        setImpliedCostOfEquity("");
        setFe0("");
        setFe1("");
        setFe2("");
        setIntrinsicValue("");
        setProfitVolumeRatio("");
        setAssetsInPlace("");
        setPVGO("");
        setPlowbackRate("");
        setROI("");
        setValueOfEquity("");
        setValueOfEquityUnit("");
        setFirmValue("");
        setFirmValueUnit("");
        setEnterpriseValue("");
        setEnterpriseValueUnit("");

        // Reset Alternates
        setAltFy1("");
        setAltFy2("");
        setAltPlowbackRate("");
        setAltBeta("");
        setAltRFR("");
        setOrigROI("");
    };

    // Function to handle user input changes
    const handleInputChange = (e, setter) => {
        const newValue = e.target.value;
        // Use a regular expression to allow only numbers and a single dot
        const regex = /^[0-9]*\.?[0-9]*$/;
    
        if (regex.test(newValue)) {
          setter(newValue);
        }
    };

    const handleInputChangeInt = (e, setter) => {
        const newValue = e.target.value;
        // Use a regular expression to allow only numbers
        const regex = /^[0-9]*$/;

        if (regex.test(newValue)) {
            setter(newValue);
        }
    };

    // Only allow digits & one '.'
    const handleFloatKeyDown = (e) => {
        const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab", "Home", "End", "Control", "Shift"];
        if (allowedKeys.includes(e.key)) return;
        
        // Allow digits.
        if (/^\d$/.test(e.key)) return;
        
        // Allow a dot if one isn't already present.
        if (e.key === ".") {
            if (e.target.value.includes(".")) {
                e.preventDefault();
            }
            return;
        }
        
        e.preventDefault();
    };
    
    // Only allow digits
    const handleIntegerKeyDown = (e) => {
        // Allow control keys (navigation, deletion, etc.)
        const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab", "Home", "End", "Control", "Shift"];
        if (allowedKeys.includes(e.key)) return;
        
        // Allow any digit (each event is for one character)
        if (/^\d$/.test(e.key)) return;
        
        e.preventDefault();
    };
      
    const handleTickerChange = (e) => {
        if(e.target.value !== ticker_input) {
            setTicker(e.target.value);
        }
    };

    const toggleInstructions = () => setShowInstructions(!showInstructions);

    const handleKeyDown = (event) => {
        if(event.key === 'Enter') {
            run();
        }
    }

    const calculateUnits = (value, decimal_precision) => {
        const units = ['', 'thousands', 'millions', 'billions', 'trillions', 'quadrillions', 'quintillions', 'sextillions', 'septillions', 'octillions', 'nonillions', 'decillions'];
        const index = Math.floor(Math.log10(Math.abs(value)) / 3);
        const scaledValue = (value / Math.pow(10, index * 3));
        return {
            value: Number(scaledValue.toFixed(decimal_precision)),
            unit: units[index]
        };
    };

    const reverseCalculateUnits = (scaledValue, unit) => {
        const units = ['', 'thousands', 'millions', 'billions', 'trillions', 'quadrillions', 'quintillions', 'sextillions', 'septillions', 'octillions', 'nonillions', 'decillions'];
    
        // Find the index of the given unit
        const index = units.indexOf(unit);
    
        if (index === -1) {
            throw new Error('Invalid unit provided.');
        }
    
        // Calculate the original value using the formula: originalValue = scaledValue * 10^(index * 3)
        const originalValue = scaledValue * Math.pow(10, index * 3);
    
        return originalValue;
    };

    const validateModelInputs = () => {
        if (forecast_period < 5 || forecast_period > 30) {
            setError("Invalid Input: Finite Forecast Period must be between 5 and 30");
            return false;
        }
        if (risk_free_rate_perc <= 0) {
            setError("Invalid Input: Risk Free Rate must be positive");
            return false;
        }
        if (risk_premium_perc <= 0) {
            setError("Invalid Input: Risk Premium must be positive");
            return false;
        }
        if (eps_growth_perc <= 0) {
            setError("Invalid Input: Steady EPS Growth must be positive");
            return false;
        }
        return true;
    }

    const validateAllInputs = () => {
        if (!validateModelInputs()) { return false; }

        if (fy1 === "" || fy1 <= 0) {
            setError("Invalid Input: Forecasted EPS (FY1) must be positive");
            return false;
        }
        if (fy2 === "" || fy2 <= 0) {
            setError("Invalid Input: Forecasted EPS (FY2) must be positive");
            return false;
        }
        if (plowback_rate === "" || plowback_rate < 0.1 || plowback_rate > 1.25) {
            setError("Invalid Input: Plowback Rate must be between 0.1 and 1.25");
            return false;
        }
        if (growth_rate_input === "" || growth_rate_input <= 0 || growth_rate_input > 75) {
            setError("Invalid Input: Growth Rate (FY2) must be between 0% and 75%");
            return false;
        }
        if (beta === "" || beta <= -0.25) {
            setError("Invalid Input: Beta (Raw) must be greater than -0.25");
            return false;
        }
        if (roi_perc !== "" && roi_perc !== orig_roi && roi_perc <= 0) {
            setError("Invalid Input: Steady-state ROI must be positive.");
            return false;
        }
        // We only check cost of equity if cost of equity has been computed already
        if (cost_of_equity !== "" && parseFloat((eps_growth_perc / 100).toFixed(4)) >= cost_of_equity) {
            setError("Invalid Input: Steady EPS Growth cannot exceed Cost of Equity (CAPM)");
            return false;
        }
        return true;
    };

    // Sets data to "" if null, actual (rounded if editable) otherwise
    const validateData = (data) => {
        return {
            risk_free_rate: data["risk_free_rate"] == null ? null : Number(data["risk_free_rate"].toFixed(4)), // TODO: Move to separate API 
            name: data["name"],
            website: data["website"],
            industry: data["industry"],
            sector: data["sector"],
            fy0: data["fy0"] == null ? "" : parseFloat(data["fy0"]),
            fy1: data["fy1"] == null ? "" : parseFloat(data["fy1"].toFixed(2)),
            fy2: data["fy2"] == null ? "" : parseFloat(data["fy2"].toFixed(2)),
            stock_price: data["stock_price"] == null ? "" : parseFloat(data["stock_price"]),
            beta: data["beta"] == null ? "" : parseFloat(Number(data["beta"]).toFixed(2)),
            monthsToFYE: data["monthsToFYE"] == null ? "" : parseFloat(data["monthsToFYE"]),
            book_value: data["book_value"] == null ? "" : parseFloat(data["book_value"]),
            shares: parseInt(data["shares"]),
            debt: parseInt(data["debt"]),
            cash: parseInt(data["cash"]),
            payout_ratio: (data["payout_ratio"] !== null || data["payout_ratio"] === 0) ? parseFloat(data["payout_ratio"].toFixed(2)) : "",
            dividends_paid: data["dividends_paid"] == null ? "" : parseFloat(data["dividends_paid"].toFixed(2)),
            net_stock_issuance: data["net_stock_issuance"] == null ? "" : parseFloat(data["net_stock_issuance"].toFixed(2)),
            net_income: data["net_income"]
        }
    };

    const restore = () => {
        setStatus("Restoring...");
        setTicker(ticker);

        // Reset Model Params
        setRiskPremium(5.00);
        setEpsGrowth(5.00);
        setTransYears(15);
        setRiskFreeRate(alt_rfr);

        // Restore alternate inputs for editable fields
        setFY1(alt_fy1);
        setFY2(alt_fy2);
        setPlowbackRate(alt_plowback_rate);
        setBeta(alt_beta);

        clearOutputs();

        let missing_fields = []
        if (fy0 == "")                              missing_fields.push("Current FY EPS");
        if (monthsToFYE === "")                     missing_fields.push("Fiscal Year End");
        if (stock_price === "")                     missing_fields.push("Stock Price");
        if (shares === "")                          missing_fields.push("# Shares");
        if (debt === "")                            missing_fields.push("Debt");
        if (cash === "")                            missing_fields.push("Cash");
        if (alt_fy1 === "" || alt_fy1 <= 0 
            || alt_fy2 === "" || alt_fy2 <= 0)      missing_fields.push("EPS Forecast (FY1/FY2)");
        if (alt_plowback_rate === "")               missing_fields.push("Plowback Rate");
        if (alt_beta === "" || alt_beta <= -0.25)   missing_fields.push("Beta");

        let computedGR = 0;
        if (alt_fy1 !== "" && alt_fy2 !== "" && alt_fy1 > 0 && alt_fy2 > 0) {
            computedGR = parseFloat(compute_growth_rate(parseFloat(alt_fy1), parseFloat(alt_fy2)).toFixed(4));
            setGrowthRate(parseFloat((computedGR * 100).toFixed(2)));
        } else {
            missing_fields.push("EPS Growth (FY2)");
        }

        if (missing_fields.length > 0) {
            setStatus("Error");
            setGettingValues(false);
            setError("Data Unavailable: " + missing_fields.join(", "));
            return;
        }

        const params = {
            fy0: parseFloat(fy0),
            fy1: parseFloat(alt_fy1),
            fy2: parseFloat(alt_fy2),
            monthsToFYE: parseFloat(monthsToFYE),
            risk_free_rate: parseFloat(alt_rfr) / 100,
            beta: parseFloat(alt_beta),
            risk_premium: 0.05, // parseFloat(risk_premium_perc) / 100,
            plowback_rate: parseFloat(alt_plowback_rate),
            eps_growth: 0.05, // parseFloat(eps_growth_perc) / 100,
            stock_price: parseFloat(stock_price),
            forecast_period: 15, // parseFloat(forecast_period),
            roi: parseFloat(roi_perc) / 100,
            growthrate: computedGR,
            shares: reverseCalculateUnits(shares, shares_unit),
            debt: reverseCalculateUnits(debt, debt_unit),
            cash: reverseCalculateUnits(cash, cash_unit),
        };

        calculateValues(params);
        setStatus("Restored!");
    };

    const clearOutputs = () => {
        setFe0("");
        setFe1("");
        setFe2("");
        setAdjustedBeta("");
        setCostOfEquity("");
        setImpliedCostOfEquity("");
        setIntrinsicValue("");
        setProfitVolumeRatio("");
        setAssetsInPlace("");
        setPVGO("");
        setValueOfEquity("");
        setValueOfEquityUnit("");
        setFirmValue("");
        setFirmValueUnit("");
        setEnterpriseValue("");
        setEnterpriseValueUnit("");
    };

    const calculateValues = (params, useROI = true) => {
        setStatus("Calculating Values...");
    
        // Params should be given already in decimal form
        const {
            fy0,
            fy1,
            fy2,
            monthsToFYE,
            risk_free_rate,
            beta,
            risk_premium,
            plowback_rate,
            eps_growth,
            stock_price,
            forecast_period,
            roi,
            growthrate,
            shares,
            debt,
            cash,
        } = params;

        // Compute cost of equity
        const capm = compute_cost_of_equity(beta, risk_free_rate, risk_premium);
        capm.adjusted_beta = parseFloat(capm.adjusted_beta.toFixed(2));
        capm.cost_of_equity = parseFloat(capm.cost_of_equity.toFixed(4));
        setAdjustedBeta(capm.adjusted_beta);
        setCostOfEquity(capm.cost_of_equity);

        // Check for EPS Growth set too high
        if (capm.cost_of_equity <= eps_growth) {
            setError(`Invalid Input: Steady EPS Growth cannot exceed Cost of Equity (CAPM)`);
            setStatus("Error");

            clearOutputs();
            setAdjustedBeta(capm.adjusted_beta);
            setCostOfEquity(capm.cost_of_equity);
            return;
        }

        if (!useROI) {
            setROI(parseFloat((capm.cost_of_equity * 100).toFixed(2)));
            setOrigROI(parseFloat((capm.cost_of_equity * 100).toFixed(2)));
        }

        const effectiveROI = useROI ? roi : capm.cost_of_equity;

        // Compute earnings forecast using effective growth rate
        const earnings_forecast = compute_earnings_forecast(fy0, fy1, fy2, monthsToFYE, growthrate);
        earnings_forecast.fe0 = parseFloat(earnings_forecast.fe0.toFixed(2));
        earnings_forecast.fe1 = parseFloat(earnings_forecast.fe1.toFixed(2));
        earnings_forecast.fe2 = parseFloat(earnings_forecast.fe2.toFixed(2));
        setFe0(earnings_forecast.fe0);
        setFe1(earnings_forecast.fe1);
        setFe2(earnings_forecast.fe2);

        // console.log(`
        //     ${earnings_forecast.fe0},
        //     ${earnings_forecast.fe1},
        //     ${earnings_forecast.fe2},
        //     ${capm.cost_of_equity},
        //     ${shares},
        //     ${debt},
        //     ${cash},
        //     ${plowback_rate},
        //     ${eps_growth},
        //     ${stock_price},
        //     ${growthrate},
        //     ${forecast_period},
        //     ${effectiveROI}
        // `);

        // Call the WASM function for valuation
        const valuation = compute_intrinsic_value(
            earnings_forecast.fe0,
            earnings_forecast.fe1,
            earnings_forecast.fe2,
            capm.cost_of_equity,
            shares,
            debt,
            cash,
            plowback_rate,
            eps_growth,
            stock_price,
            growthrate,
            forecast_period,
            effectiveROI
        );

        // console.log(JSON.stringify(valuation, null, 4));

        setImpliedCostOfEquity(parseFloat(valuation.cost_of_equity_implied));
        setIntrinsicValue(parseFloat(valuation.intrinsic_value));
        setProfitVolumeRatio(parseFloat(valuation.pv_ratio));
        setAssetsInPlace(parseFloat(valuation.assets_in_place));
        setPVGO(parseFloat(valuation.pvgo));
    
        // Convert firm/equity/enterprise values to scaled units
        const equityValueData = calculateUnits(valuation.value_of_equity, 2);
        setValueOfEquity(equityValueData.value);
        setValueOfEquityUnit(equityValueData.unit);
    
        const firmValueData = calculateUnits(valuation.firm_value, 2);
        setFirmValue(firmValueData.value);
        setFirmValueUnit(firmValueData.unit);
    
        const enterpriseValueData = calculateUnits(valuation.enterprise_value, 2);
        setEnterpriseValue(enterpriseValueData.value);
        setEnterpriseValueUnit(enterpriseValueData.unit);
    
        setStatus("Done!");
        setError("");
    };

    const getValues = async() => {
        let temp_ticker = ticker_input.replace(/\s+/g, '').toUpperCase();
        if(temp_ticker === "") {
            setError("Please Enter Valid Ticker");
            setStatus("Error");
            return;
        }
        if(getting_values) {
            setError("Already Calculating Values");
            return;
        }
        // if (!validateModelInputs()) {
        //     setStatus("Error");
        //     return;
        // } // Reset model values every time we get new ticker

        const url = '/api/stock-info/' + temp_ticker;
        try {
            setGettingValues(true);
            const response = await axios.get(url);
            setStatus("Calculating Values...");
            const data = response.data;

            // An Error indicates something more serious than 
            // some data points being unavailable
            if (data.hasOwnProperty("error")) {
                setGettingValues(false);
                setStatus("Error");

                if (data["error"] === "Invalid Ticker") {
                    setError("Please Enter Valid Ticker");
                } else {
                    setError("Error Retrieving Data: " + data["error"]);
                }
                
                return;
            }
            
            resetValues();
            const parsed_data = validateData(data);
            // console.log(JSON.stringify(parsed_data, null, 4));

            setBaseTicker(ticker_input);
            setCorpName(parsed_data.name);
            setCorpWebsite(parsed_data.website);
            setCorpIndustry(parsed_data.industry);
            setCorpSector(parsed_data.sector);
            setFy0(parsed_data.fy0);
            setFY1(parsed_data.fy1);
            setFY2(parsed_data.fy2);
            setStockPrice(parsed_data.stock_price);
            setBeta(parsed_data.beta);
            setMonthsToFYE(parsed_data.monthsToFYE);
            setBookValue(parsed_data.book_value);

            setAltFy1(parsed_data.fy1);
            setAltFy2(parsed_data.fy2);
            setAltBeta(parsed_data.beta);

            setRiskFreeRate(parseFloat((parsed_data.risk_free_rate * 100).toFixed(2))); // TODO: Remove (Use Separate API for RFR)
            setAltRFR(parseFloat((parsed_data.risk_free_rate * 100).toFixed(2)));

            if (parsed_data.shares !== null) {
                const sharesData = calculateUnits(parsed_data.shares, 2);
                setShares(sharesData.value);
                setSharesUnit(sharesData.unit);
            }
            if (parsed_data.debt !== null) {
                const debtData = calculateUnits(parsed_data.debt, 2);
                setDebt(debtData.value);
                setDebtUnit(debtData.unit);
            }
            if (parsed_data.cash !== null) {
                const cashData = calculateUnits(parsed_data.cash, 2);
                setCash(cashData.value);
                setCashUnit(cashData.unit);
            }

            let missing_fields = []
            if (parsed_data.fy0 == "")                              missing_fields.push("Current FY EPS");
            if (parsed_data.fy1 === "" || parsed_data.fy2 === "")   missing_fields.push("EPS Forecast (FY1/FY2)");
            if (parsed_data.monthsToFYE === "")                     missing_fields.push("Fiscal Year End");
            if (parsed_data.stock_price === "")                     missing_fields.push("Stock Price");
            if (parsed_data.beta === "")                            missing_fields.push("Beta");
            if (parsed_data.shares === "")                          missing_fields.push("# Shares");
            if (parsed_data.debt === "")                            missing_fields.push("Debt");
            if (parsed_data.cash === "")                            missing_fields.push("Cash");

            let plowback_rate = 1.0;
            if (parsed_data.dividends_paid !== "" && parsed_data.net_income !== "") {
                // Dividends Paid & Stock Issuance are Negative (Cash Outflows), so sub instead of add
                const stock_issuance =  parsed_data.net_stock_issuance !== "" ? parsed_data.net_stock_issuance : 0;
                const alt_payout = (0 - parsed_data.dividends_paid - stock_issuance) / parsed_data.net_income
                plowback_rate = 1 - alt_payout;
            } else if (parsed_data.payout_ratio !== "") {
                plowback_rate = 1 - parsed_data.payout_ratio;
            } else {
                missing_fields.push("Plowback Rate");
                return;
            }
            plowback_rate = Math.max(0.1, Math.min(plowback_rate, 1.25)); // Clamp to 0-1
            plowback_rate = parseFloat(plowback_rate.toFixed(2));         // Round to 2 decimal places
            setPlowbackRate(plowback_rate);
            setAltPlowbackRate(plowback_rate);

            let growthrate = 0;
            if (parsed_data.fy1 !== "" && parsed_data.fy2 !== "" && parsed_data.fy1 > 0 && parsed_data.fy2 > 0) {
                growthrate = parseFloat(compute_growth_rate(parsed_data.fy1, parsed_data.fy2).toFixed(4));
                setGrowthRate(parseFloat((growthrate * 100).toFixed(2)));
            } else {
                missing_fields.push("EPS Growth (FY2)");
            }

            if (missing_fields.length > 0) {
                setStatus("Error");
                setGettingValues(false);
                setError("Data Unavailable: " + missing_fields.join(", "));
                return;
            }
            if (parsed_data.beta !== "" && data["beta"] <= -0.25) {
                setStatus("Error");
                setGettingValues(false);
                setError("Firm Cannot Be Valued: Beta (Raw) must be greater than -0.25");
                return;
            }
            if (parsed_data.fy1 !== "" && parsed_data.fy2 !== "" && (parsed_data.fy1 <= 0 || parsed_data.fy2 <= 0)) {
                setError("Firm Cannot Be Valued: Negative Projected Earnings");
                setGettingValues(false);
                setStatus("Error");
                return;
            }

            const params = {
                fy0: parsed_data.fy0,
                fy1: parsed_data.fy1,
                fy2: parsed_data.fy2,
                monthsToFYE: parsed_data.monthsToFYE,
                risk_free_rate: parsed_data.risk_free_rate,
                beta: parsed_data.beta,
                risk_premium: 0.05, // parseFloat(risk_premium_perc) / 100,
                plowback_rate: plowback_rate,
                eps_growth: 0.05, // parseFloat(eps_growth_perc) / 100,
                stock_price: parsed_data.stock_price,
                forecast_period: 15, // parseFloat(forecast_period),
                roi: null,
                growthrate: growthrate,
                shares: parsed_data.shares,
                debt: parsed_data.debt,
                cash: parsed_data.cash,
            };

            calculateValues(params, false);
            setGettingValues(false);
            setStatus("Done!");
            setError("");
        } catch(error) {
            setGettingValues(false);
            setStatus("Error");
            if(error.response && error.response.status === 400) {
                setError("Please input a valid ticker.");
            } else if(error.response && error.response.status === 404) {
                setError("A connection to the server could not be established.");
            } else if(error.response && error.response.status === 500) {
                setError("Unknown Server Error.");
            } else {
                setError("Unknown Error: " + error);
                console.log(error);
            }
        }
    };

    const run = () => {
        if(ticker_input === ticker && getting_values === false && ticker !== "") {
            // We are recomputing with new inputs if here
            if (!validateAllInputs()) return;

            // If ROI is not set or not modified, we just use the computed cost of equity instead.
            let overrideROI = true;
            if (roi_perc === "" || roi_perc === orig_roi) {
                overrideROI = false;
            }

            // Convert input state values to decimals.
            const parsedFY1 = parseFloat(fy1);
            const parsedFY2 = parseFloat(fy2);
            const parsedPlowback = parseFloat(plowback_rate);
            const parsedRiskFreeRate = parseFloat(risk_free_rate_perc) / 100;
            const parsedRiskPremium = parseFloat(risk_premium_perc) / 100;
            const parsedEPSGrowth = parseFloat(eps_growth_perc) / 100;
            const parsedROI = parseFloat(roi_perc) / 100;
            const parsedGrowthInput = parseFloat(growth_rate_input) / 100;

            const params = {
                fy0: parseFloat(fy0),
                fy1: parsedFY1,
                fy2: parsedFY2,
                monthsToFYE: parseFloat(monthsToFYE),
                risk_free_rate: parsedRiskFreeRate,
                beta: parseFloat(beta),
                risk_premium: parsedRiskPremium,
                plowback_rate: parsedPlowback,
                eps_growth: parsedEPSGrowth,
                stock_price: parseFloat(stock_price),
                forecast_period: parseFloat(forecast_period),
                roi: parsedROI,
                growthrate: parsedGrowthInput,
                shares: reverseCalculateUnits(shares, shares_unit),
                debt: reverseCalculateUnits(debt, debt_unit),
                cash: reverseCalculateUnits(cash, cash_unit),
            };

            calculateValues(params, overrideROI);

        } else {
            // We are getting values for new ticker if here
            setStatus("Retrieving Data...");
            getValues();
        }
    };

    return (
        <div>
            <div className="ticker-section">
                <div className="left-ticker-section">
                    <label className="ticker-label">Enter Ticker:</label>
                    <input type="text" value={ticker_input} onChange={(e) => handleTickerChange(e, setTicker)} size="6" maxLength="6" onKeyDown={handleKeyDown} placeholder="NVDA"/>
                    {/* <TickerInput
                        label="Ticker"
                        id="tickerInput"
                        type="text"
                        value={ticker_input}
                        onChange={(e) => handleTickerChange(e, setTicker)}
                        size="6"
                        maxLength="6"
                        onKeyDown={handleKeyDown}
                    /> */}
                    <button onClick={run}>Calculate</button>
                    <button onClick={restore}>Restore</button>
                    <label className="status-indicator">{status}</label>
                </div>
                <div className="right-ticker-section">
                    <button id="instruc-but" onClick={toggleInstructions}>{showInstructions ? "Hide" : "Show" } Instructions</button>
                </div>
            </div>
            <div className={`instructions ${showInstructions ? "show" : ""}`}>
                <ul>
                    <li>Type in a Ticker for a US publicly traded company in the Ticker box.</li>
                    <li>Click the "Calculate" button to compute valuations using Yahoo Finance data.</li>
                    <li>You can also input your own data in some boxes and click "Calculate" for your own valuations.</li>
                    <li>To restore the Yahoo Finance data, simply click the "Restore" button.</li>
                </ul>
            </div>
            <div className="company-info-section">
                <div className="company-info-left">
                <span className="company-name">{corp_name}</span>
                {ticker && (
                    <a href={`https://finance.yahoo.com/quote/${ticker}/`} target="_blank" rel="noopener noreferrer" >
                        <i className="fas fa-external-link-alt"></i>
                    </a>
                )}
                </div>
                <div className="company-info-right">
                    Industry: <span className="company-industry">{corp_industry}</span>
                </div>
            </div>
            <div className="flex-container">
                <div className="left-section">
                    <table id="static-inp-data">
                        <tr>
                            <th colSpan="2">Static Input Data</th>
                        </tr>
                        <tr>
                            <td>Current Fiscal Year EPS ($)</td>
                            <td>{fy0 !== "" ? fy0.toFixed(2) : "-"}</td>
                        </tr>
                        <tr>
                            <td>Months to Fiscal Year End</td>
                            <td>{(monthsToFYE === 0 || monthsToFYE) ? monthsToFYE : "-"}</td>
                        </tr>
                        <tr>
                            <td>Trailing EPS (12 mos) ($)</td>
                            <td>{fe0 !== "" ? fe0.toFixed(2) : "-"}</td>
                        </tr>
                        <tr>
                            <td>Forecasted EPS (Next 12 mos) ($)</td>
                            <td>{fe1 !== "" ? fe1.toFixed(2) : "-"}</td>
                        </tr>
                        <tr>
                            <td>Forecasted EPS (Following 12 mos) ($)</td>
                            <td>{fe2 !== "" ? fe2.toFixed(2) : "-"}</td>
                        </tr>
                        <tr>
                            <td>Current Stock Price ($)</td>
                            <td>{stock_price !== "" ? stock_price.toFixed(2) : "-"}</td>
                        </tr>
                        <tr>
                            <td>Book Value Per Share ($)</td>
                            <td>{book_value !== "" ? book_value.toFixed(2) : "-"}</td>
                        </tr>
                        <tr>
                            <td>Shares Outstanding {shares_unit !== "" ? `(${shares_unit})` : ""}</td>
                            <td>{shares !== "" ? shares.toFixed(2) : "-"}</td>
                        </tr>
                        <tr>
                            <td>Total Debt ({debt_unit !== "" ? debt_unit + " " : ""}$)</td>
                            <td>{debt !== "" ? debt.toFixed(2) : "-"}</td>
                        </tr>
                        <tr>
                            <td>Total Cash ({cash_unit !== "" ? cash_unit + " " : ""}$)</td>
                            <td>{cash !== "" ? cash.toFixed(2) : "-"}</td>
                        </tr>
                    </table>
                    <table id="costOfEquity">
                        <tr>
                            <th colSpan="2">
                                CAPM Cost of Equity Calculation
                                <span className="tooltip" id="tooltip-header" data-tooltip="CAPM: Capital Asset Pricing Model"></span>
                            </th>
                        </tr>
                        <tr>
                            <td>
                                Risk-Free Rate
                                <span className="tooltip" data-tooltip="Yield on 30-year US govt. bond"></span>
                            </td>
                            <td className="input-cell-percentage">
                                <div class="percentage-cell-content">
                                    <input type="number" value={risk_free_rate_perc} placeholder="-" step="0.01" min="0.01" onChange={(e) => handleInputChange(e, setRiskFreeRate)} onKeyDown={handleFloatKeyDown} />
                                    <span>%</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Market Risk Premium
                                <span className="tooltip" data-tooltip="(rm - rf) Set to 5% by default."></span>
                            </td>
                            <td className="input-cell-percentage">
                                <div class="percentage-cell-content">
                                    <input type="number" value={risk_premium_perc} placeholder="-" step="0.01" min="0.01" onChange={(e) => handleInputChange(e, setRiskPremium)} onKeyDown={handleFloatKeyDown} />
                                    <span>%</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Beta (Raw)</td>
                            <td className="inputCell">
                                <input type="number" value={beta} placeholder="-" step="0.01" min="0.01" onChange={(e) => handleInputChange(e, setBeta)} onKeyDown={handleFloatKeyDown} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Beta (Adjusted)
                                <span className="tooltip" data-tooltip="(1/3 + 2/3 * Raw Beta)"></span>
                            </td>
                            <td>{adjusted_beta !== "" ? adjusted_beta.toFixed(2) : "-"}</td>
                        </tr>
                        <tr>
                            <td>
                                Cost of Equity (CAPM)
                                <span className="tooltip" data-tooltip="Cost of Equity determined by Capital Asset Pricing Model"></span>
                            </td>
                            <td>
                                <div class="percentage-cell-content">
                                    <span>{cost_of_equity !== "" ? (cost_of_equity*100).toFixed(2) : "-"}</span>
                                    <span>%</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Cost of Equity (Implied)
                                <span className="tooltip" data-tooltip="The internal rate of return (IRR) that equates the intrinsic value to its price."></span>
                            </td>
                            <td>
                                <div class="percentage-cell-content">
                                    <span>{implied_cost_of_equity !== "" ? (implied_cost_of_equity*100).toFixed(2) : "-"}</span>
                                    <span>%</span>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
                <div className="right-section">
                    <table id="editable-inp-data">
                        <tr>
                            <th colSpan="2">Editable Input Data</th>
                        </tr>
                        <tr>
                            <td>Forecasted EPS (FY1) ($)</td>
                            <td className="inputCell">
                                <input type="number" value={fy1} placeholder="-" step="0.01" min="0.01" onChange={(e) => handleInputChange(e, setFY1)} onKeyDown={handleFloatKeyDown} />
                            </td>
                        </tr>
                        <tr>
                            <td>Forecasted EPS (FY2) ($)</td>
                            <td className="inputCell">
                                <input type="number" value={fy2} placeholder="-" step="0.01" min="0.01" onChange={(e) => handleInputChange(e, setFY2)} onKeyDown={handleFloatKeyDown} />
                            </td>
                        </tr>
                        <tr>
                            <td>Forecasted EPS Growth (FY2)</td>
                            <td className="input-cell-percentage">
                                <div class="percentage-cell-content">
                                    <input type="number" value={growth_rate_input} placeholder="-" step="0.01" min="1" max="75" onChange={(e) => handleInputChange(e, setGrowthRate)} onKeyDown={handleFloatKeyDown} />
                                    <span>%</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Plowback Rate
                                <span className="tooltip" data-tooltip="(1 - Payout Ratio)"></span>
                            </td>
                            <td className="input-cell-percentage">
                                <input type="number" value={plowback_rate} placeholder="-" step="0.01" max="1.25" min="0.1" onChange={(e) => handleInputChange(e, setPlowbackRate)} onKeyDown={handleFloatKeyDown} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                 Steady-state EPS Growth
                                <span className="tooltip" data-tooltip="Long-term growth, usually set to nominal GDP. Cannot exceed Cost of Equity."></span>
                            </td>
                            <td className="input-cell-percentage">
                                <div class="percentage-cell-content">
                                    <input type="number" value={eps_growth_perc} placeholder="-" step="0.01" min="0.01" onChange={(e) => handleInputChange(e, setEpsGrowth)} onKeyDown={handleFloatKeyDown} />
                                    <span>%</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Steady-state ROI
                                <span className="tooltip" data-tooltip="Set to Cost of Equity"></span>
                            </td>
                            <td className="input-cell-percentage">
                                <div class="percentage-cell-content">
                                    <input type="number" value={roi_perc} placeholder="-" step="0.01" min="0.01" onChange={(e) => handleInputChange(e, setROI)} onKeyDown={handleFloatKeyDown} />
                                    <span>%</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Finite Forecast Period (Years)</td>
                            <td className="inputCell">
                                <input type="number" value={forecast_period} placeholder="-" step="1" min="5" max="30" onChange={(e) => handleInputChangeInt(e, setTransYears)} onKeyDown={handleIntegerKeyDown} />
                            </td>
                        </tr>
                    </table>
                    <table id="valuationTable">
                        <tr>
                            <th colSpan="2">Stock & Firm Valuation</th>
                        </tr>
                        <tr>
                            <td>Value of Equity Per Share ($)</td>
                            <td>{intrinsic_value_of_equity_per_share_dfc !== "" ? intrinsic_value_of_equity_per_share_dfc.toFixed(2) : "-"}</td>
                        </tr>
                        <tr>
                            <td>
                                P/V Ratio
                                <span className="tooltip" data-tooltip="Profit Volume Ratio"></span>
                            </td>
                            <td>{profit_volume_ratio !== "" ? profit_volume_ratio.toFixed(2) : "-"}</td>
                        </tr>
                        <tr>
                            <td>Value of assets-in-place ($)</td>
                            <td>{assets_in_place_value !== "" ? assets_in_place_value.toFixed(2) : "-"}</td>
                        </tr>
                        <tr>
                            <td>
                                PVGO ($)
                                <span className="tooltip" data-tooltip="Present Value of Growth Opportunities"></span>
                            </td>
                            <td>{pvgo !== "" ? pvgo.toFixed(2) : "-"}</td>
                        </tr>
                        <tr>
                            <td>Value of Equity ({value_of_equity_unit} $)</td>
                            <td>{value_of_equity !== "" ? value_of_equity.toFixed(2) : "-"}</td>
                        </tr>
                        <tr>
                            <td>Value of Debt ({debt_unit} $)</td>
                            <td>{debt !== "" ? debt.toFixed(2) : "-"}</td>
                        </tr>
                        <tr>
                            <td>Total Firm Value ({firm_value_unit} $)</td>
                            <td>{total_firm_value !== "" ? total_firm_value.toFixed(2) : "-"}</td>
                        </tr>
                        <tr>
                            <td>
                                Total Enterprise Value ({enterprise_value_unit} $)
                                <span className="tooltip" data-tooltip="(Total Firm Value - Cash)"></span>
                            </td>
                            <td>{total_enterprise_value !== "" ? total_enterprise_value.toFixed(2) : "-"}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Table;