import './App.css';
import React, { useEffect, useState } from 'react';
import init from './wasm/rust_wasm';
import Table from './components/Table';

function App() {
    const [error, setError] = useState("");
    const [wasmReady, setWasmReady] = useState(false);

    useEffect(() => {
        async function loadWasm() {
        await init({
            locateFile: (file) => `/wasm/${file}`,
        });
        setWasmReady(true);
        }
        loadWasm();
    }, []);

    if (!wasmReady) return <div>Loading WebAssembly...</div>;

    return (
        <div className="App">
            <header className="App-header">
            <h1 id="main-header">Stock Valuation Calculator</h1>
            <h3 className="author-title">
                by <a href="https://sharingfin.com/about" target="_blank" rel="noreferrer">Bhaskaran Swaminathan</a>
            </h3>
            <div className="error-message-container">
                <div className={`error-message ${error ? "visible" : ""}`}>
                {error}
                </div>
            </div>
            </header>
            <Table setError={setError} />
            <div class="footer">
            <p>Developed by <a href="https://github.com/etbala" target="_blank" rel="noreferrer">Ethan Balakumar</a></p>
            </div>
        </div>
    );
}

export default App;
